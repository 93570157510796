import React from 'react';
import {RecoilRoot} from 'recoil';
import Routes from "./Routes";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/core/styles"
import theme from "./theme";

export default () => {
    return (
        <ThemeProvider theme={theme}>
            <RecoilRoot>
                <CssBaseline/>
                <Routes/>
            </RecoilRoot>
        </ThemeProvider>

    );
}
