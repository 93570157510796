import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Accordion, AccordionDetails, AccordionSummary, DialogTitle, MenuItem} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Select, TextField} from "unform-material-ui";
import {Form} from "@unform/web";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import api from "../services/api";
import Swal from "sweetalert2";
import {default as RSelect} from "@appgeist/react-select-material-ui"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '80vw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function Dashboard(props) {
    const classes = useStyles();
    const [open,] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const formRefNovoRegisto = useRef(null);
    const [tmp, setTmp] = useState({});
    const [novoRegisto, setAbrirNovoRegisto] = useState(false);
    const [clientes, setClientes] = useState([])
    const [clienteDefind, setClienteDefined] = useState({})
    const [idCliente,] = useState({})

    useEffect(() => {
        api.get('/cliente/all').then((res) => {
            // eslint-disable-next-line
            if (res.data.code == 0) {
                setClientes(res.data.data)

            }
        })

    }, [props])

    const [dataNovo, setDataNovo] = useState(new Date());
    const handleDateChangeNovo = (date) => {
        setDataNovo(date);
    };

    const fecharRegistoDeVisita = () => {
        setTmp(formRefNovoRegisto.current.getData())
        setAbrirNovoRegisto(false)
    }

    const abrirRegistoDeVisita = () => {
        setAbrirNovoRegisto(true)
    }

    const criarRegisto = (data) => {
        setTmp({})
        data.data = moment(dataNovo).format("DD/MM/YYYY");
        if (data.data !== undefined && (clienteDefind.id !== undefined)) {
            data.cliente = clienteDefind.id;
            api.post('/registo', data).then(async r => {
                if (r.data.code === 0) {
                    fecharRegistoDeVisita()
                    Swal.fire({
                        icon: 'success',
                        title: r.data.message,
                        timer: 1000
                    })
                } else {
                    fecharRegistoDeVisita()
                    await Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: r.data.message,
                        timer: 2500

                    })
                    abrirRegistoDeVisita()
                }
            })
        } else {
            setTmp(formRefNovoRegisto.current.getData())
            fecharRegistoDeVisita()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Não foi preenchido o cliente',
                timer: 2500
            }).then(() => {
                abrirRegistoDeVisita()
            })
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardContent>
                        <Typography component="h1" variant="h5">
                            Dashboard
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Link to={"/documentos"}><Button fullWidth={true} variant={"contained"}
                                                                 color={"primary"}>Realizar encomenda</Button></Link>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Link to={"/orcamentos"}><Button fullWidth={true} variant={"contained"}
                                                                 color={"primary"}>Realizar orçamento</Button></Link>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Link to={"/clientes"}>
                                    <Button fullWidth={true} variant={"contained"}
                                            color={"primary"}>Ver clientes</Button>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} onClick={abrirRegistoDeVisita} variant={"contained"}
                                        color={"primary"}>Registo de Visita</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={() => {
            }}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            {/* DIALOGS */}


            {/* Registo diario */}

            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Form ref={formRefNovoRegisto} onSubmit={criarRegisto} initialData={tmp}>
                    <Dialog fullScreen={fullScreen} open={novoRegisto} onClose={fecharRegistoDeVisita}
                            aria-labelledby="Novo registo">
                        <DialogTitle>Novo registo</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <DatePicker
                                        style={{width: '100%', marginTop: '-1px'}}
                                        inputVariant="outlined"
                                        format="DD/MM/YYYY"
                                        fullwidth={true}
                                        margin="normal"
                                        label="Data"
                                        value={dataNovo}
                                        onChange={handleDateChangeNovo}
                                        KeyboardButtonProps={{
                                            'aria-label': 'Definir data',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <RSelect
                                        options={clientes}
                                        getOptionLabel={option => option.OrganizationName}
                                        getOptionValue={option => option.id}
                                        placeholder={"Clientes"}
                                        isClearable
                                        //menuPortalTarget={document.getElementsByClassName("MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6")[0]}
                                        menuPosition={'fixed'}
                                        isSearchable
                                        menuPortalTarget={document.body}
                                        styles={{menuPortal: base => ({...base, zIndex: 9999})}}
                                        // eslint-disable-next-line
                                        defaultValue={idCliente !== undefined ? clientes.filter(row => {
                                            // eslint-disable-next-line
                                            if (row.id == props.match.params.cliente) {
                                                return row
                                            }
                                        }) : null}
                                        onChange={option => setClienteDefined(option)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="pessoa_contacto"
                                        label="Pessoa de contacto"
                                        type="text"
                                        fullWidth
                                        variant={"outlined"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Select variant={"outlined"} style={{width: '100%'}} name="local" label="Local"
                                            required>
                                        <MenuItem value="telefone">Telefone</MenuItem>
                                        <MenuItem value="presencial">Presencial</MenuItem>
                                        <MenuItem value="email">Email</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="venda"
                                        >
                                            <Typography>Detalhes de venda</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        name="conversa_assunto_venda"
                                                        label="Conversa sobre venda"
                                                        type="text"
                                                        fullWidth
                                                        variant={"outlined"}
                                                        multiline={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        name="observacoes_venda"
                                                        label="Observações sobre a venda"
                                                        type="text"
                                                        fullWidth
                                                        variant={"outlined"}
                                                        multiline={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            variant={"outlined"}
                                                            name="valorVenda"
                                                            label="Valor Venda"
                                                            type="number"
                                                            fullWidth
                                                        />
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    <Select variant={"outlined"}  defaultValue={"false"} style={{width: '100%'}} name="venda" label="Teve venda"
                                                            required>
                                                        <MenuItem value="true">Sim</MenuItem>
                                                        <MenuItem value="false">Não</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            aria-controls="panel1a-content"
                                            id="recebimentos"
                                        >
                                            <Typography>Detalhes de recebimento</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        name="conversa_assunto_recebimento"
                                                        label="Conversa sobre recebimento"
                                                        type="text"
                                                        fullWidth
                                                        variant={"outlined"}
                                                        multiline={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        name="observacoes_recebimento"
                                                        label="Observações sobre recebimento"
                                                        type="text"
                                                        fullWidth
                                                        variant={"outlined"}
                                                        multiline={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={6}>
                                                    <TextField
                                                        variant={"outlined"}
                                                        name="valorRecebimento"
                                                        label="Valor recebido"
                                                        type="number"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Select variant={"outlined"} defaultValue={"false"} style={{width: '100%'}} name="recebimento" label="Teve recebimento"
                                                            required>
                                                        <MenuItem value="true">Sim</MenuItem>
                                                        <MenuItem value="false">Não</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={fecharRegistoDeVisita} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={() => formRefNovoRegisto.current.submitForm()} color="primary">
                                Guardar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            </MuiPickersUtilsProvider>
        </Container>
    );
}

