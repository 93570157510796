import React, {useEffect, useRef, useState} from "react"
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import api from "../../services/api";
import Grid from "@material-ui/core/Grid";
import {Form} from "@unform/web";
import {Select, TextField} from "unform-material-ui";
import Swal from "sweetalert2";
import {default as RSelect} from "@appgeist/react-select-material-ui"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    },
    margem: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function RegistoDiario(props) {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dataNovo, setDataNovo] = useState(new Date());
    const [reg, setReg] = useState([]);
    const [novoRegisto, setNovoRegisto] = useState(false);
    const [EditarRegisto, setEditarRegisto] = useState(false);
    const [tmp, setTmp] = useState({});
    const [newCustomer, ] = useState(false);
    const formRefNovoRegisto = useRef(null);
    const formRefEditar = useRef(null);
    const [edit, setEdit] = useState(0);
    const [clientes, setClientes]=useState([])
    const [clienteDefind, setClienteDefined]=useState({})
    const [idCliente, setIdCliente]=useState({})


    let saving = false


    useEffect(()=>{
        if(props.match.params.cliente!==undefined){
            //setNovoRegisto(true)
            // eslint-disable-next-line
            setClienteDefined(clientes.filter(row=> {
                // eslint-disable-next-line
                if(row.id == props.match.params.cliente) {
                    return row
                }
            }))
            setIdCliente(props.match.params.cliente)
        }
        // eslint-disable-next-line
    }, [props.match.params.cliente])

    useEffect(()=>{
        api.get('/cliente/all').then((res)=>{
            // eslint-disable-next-line
            if(res.data.code==0){
                setClientes(res.data.data)

            }
        })

    }, [props])

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateChangeNovo = (date) => {
        setDataNovo(date);
    };

    const fecharNovo = ()=>{
        setTmp(formRefNovoRegisto.current.getData())
        setNovoRegisto(false)
    }

    const abrirNovo = ()=>{
        setNovoRegisto(true)
        setTmp({
            cliente: props.match.params.cliente
        })
        //formRefNovoRegisto.current.setFieldValue('cliente', 'John Doe');
    }

    const fecharEditar = ()=>{
        setTmp(formRefNovoRegisto.current.getData())
        setEditarRegisto(false)
    }

    const abrirEdiar = (idx)=>{
        setEdit(idx)
        setEditarRegisto(true)
        setClienteDefined(reg[idx].cliente)
    }

    const criarRegisto=(data)=>{
        if(saving===false){
            if(!newCustomer){
                setTmp({})
                data.data=moment(dataNovo).format("DD/MM/YYYY");
                if(data.data!==undefined && (clienteDefind.id!==undefined)){
                    data.cliente=clienteDefind.id;
                    api.post('/registo', data).then(async r => {
                        if(r.data.code===0){
                            fecharNovo()
                            getData()
                            Swal.fire({
                                icon: 'success',
                                title: r.data.message,
                                timer: 1000
                            })
                        }else{
                            fecharNovo()
                            await Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: r.data.message,
                                timer: 2500

                            })
                            abrirNovo()
                        }
                    })
                }else{
                    setTmp(formRefNovoRegisto.current.getData())
                    fecharNovo()
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Não foi preenchido o cliente',
                        timer: 2500
                    }).then(()=> {
                        setNovoRegisto(true)
                    })
                }
            }
        }
    }

    const GuardarRegisto=(data)=>{
        if(clienteDefind.id===undefined) {
            data.cliente=reg[edit].cliente.id
        }else{
            data.cliente=clienteDefind.id
        }
        api.put('/registo/'+reg[edit].id, data).then(async r => {
            if(r.data.code===0){
                getData()
                fecharEditar()
                Swal.fire({
                    icon: 'success',
                    title: r.data.message,
                    timer: 1000
                })
            }else{
                fecharEditar()
                await Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: r.data.message,
                    timer: 2500
                })
                abrirEdiar(edit)
            }
        })
    }

    function send(){
        Swal.fire({
            icon: 'info',
            title: "A enviar",
        })
        api.post('/registo/send', {
            "data": moment(selectedDate).format("DD/MM/YYYY")
        }).then(r=>{
            Swal.close()
            if(r.data.code===0){
                Swal.fire({
                    icon: 'success',
                    title: r.data.message,
                    timer: 1000
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: r.data.message,
                    timer: 2500

                })
            }
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [selectedDate])

    const getData=()=>{
        api.post('registo/get', {
            "data": moment(selectedDate).format("DD/MM/YYYY")
        }).then(res => {
            if (res.status === 200) {
                if (res.data.code === 0) {
                    setReg(res.data.data)
                }
            }
        })
    }

    function apagar(){
        fecharEditar()
        Swal.fire({
            title: 'Deseja apagar o registo?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Apagar',
            cancelButtonText:"Cacelar"
        }).then((result) => {
            if(result.isConfirmed) {
                api.delete('registo/' + reg[edit].id).then(async (r) => {
                        if (r.data.code === 0) {
                            getData()
                            fecharEditar()
                            Swal.fire({
                                icon: 'success',
                                title: r.data.message,
                                timer: 1000
                            })
                        } else {
                            fecharEditar()
                            await Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: r.data.message,
                                timer: 2500

                            })
                            abrirEdiar(edit)
                        }
                    }
                )
            }
        })
    }

    const classes = useStyles();
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Container component="main" maxWidth="lg">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Registo de visitas <br/>
                    </Typography>
                    <Card className={classes.root} variant="outlined">
                        <CardContent>
                            <DatePicker
                                variant="inline"
                                format="DD/MM/YYYY"
                                margin="normal"
                                id="date-picker-inline"
                                label="Data"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'Definir data',
                                }}
                            />
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Cliente</TableCell>
                                            {/*<TableCell align={'center'}>Valor da venda</TableCell>*/}
                                            <TableCell align={'center'}>Observações</TableCell>
                                            <TableCell align={'center'}>Editar</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {reg.map((row, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell>{row.cliente.OrganizationName}</TableCell>
                                                {/*<TableCell align={'center'}>{row.valorVendido!==null?parseFloat(row.valorVenda).toFixed(2)+"€":"-"}</TableCell>*/}
                                                <TableCell align={'center'}>{row.observacoes!==null?row.observacoes:"-"}</TableCell>
                                                <TableCell align={'center'}><Button onClick={()=>abrirEdiar(idx)} variant={"contained"} color={"secondary"}>Editar</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br/>
                            <div className={classes.margem}>
                                <Button variant="contained" onClick={send} color="default">
                                    Enviar
                                </Button>
                                <Button variant="contained" onClick={abrirNovo} color="primary">
                                    Novo
                                </Button>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </Container>

            <Form ref={formRefNovoRegisto} onSubmit={criarRegisto} initialData={tmp}>
                <Dialog fullScreen={true} maxWidth={"xl"} open={novoRegisto} onClose={fecharNovo} aria-labelledby="Novo registo">
                    <Grid container>
                        <Grid item xs={10}>
                            <DialogTitle >Novo registo</DialogTitle>
                        </Grid>
                    </Grid>

                    <DialogContent>
                            <Container component="main">
                                <div className={classes.paper}>
                                    <Grid  container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <DatePicker
                                                style={{width:'100%', marginTop:'-1px'}}
                                                inputVariant="outlined"
                                                format="DD/MM/YYYY"
                                                fullwidth={true}
                                                margin="normal"
                                                label="Data"
                                                value={dataNovo}
                                                onChange={handleDateChangeNovo}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'Definir data',
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <RSelect
                                                options={clientes}
                                                getOptionLabel={option=>option.OrganizationName}
                                                getOptionValue={option=>option.id}
                                                placeholder={"Clientes"}
                                                isClearable
                                                menuPortalTarget={document.getElementsByClassName("MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6")[0]}
                                                menuPosition={'fixed'}
                                                isSearchable
                                                // eslint-disable-next-line
                                                defaultValue={idCliente!==undefined?clientes.filter(row=>{
                                                    // eslint-disable-next-line
                                                    if(row.id==props.match.params.cliente) {
                                                        return row
                                                    }
                                                }):null}
                                                onChange={option=>setClienteDefined(option)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select variant={"outlined"} style={{width: '100%'}} name="local" label="Local"
                                                    required>
                                                <MenuItem value="telefone">Telefone</MenuItem>
                                                <MenuItem value="presencial">Presencial</MenuItem>
                                                <MenuItem value="email">Email</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="pessoa_contacto"
                                                label="Pessoa de contacto"
                                                type="text"
                                                fullWidth
                                                variant={"outlined"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="venda"
                                                >
                                                    <Typography>Detalhes de venda</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                            <TextField
                                                                name="conversa_assunto_venda"
                                                                label="Conversa sobre venda"
                                                                type="text"
                                                                fullWidth
                                                                variant={"outlined"}
                                                                multiline={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <TextField
                                                                name="observacoes_venda"
                                                                label="Observações sobre a venda"
                                                                type="text"
                                                                fullWidth
                                                                variant={"outlined"}
                                                                multiline={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <TextField
                                                                variant={"outlined"}
                                                                name="valorVenda"
                                                                label="Valor Venda"
                                                                type="number"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <Select variant={"outlined"}  defaultValue={"false"} style={{width: '100%'}} name="venda" label="Teve venda"
                                                                    required>
                                                                <MenuItem value="true">Sim</MenuItem>
                                                                <MenuItem value="false">Não</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon/>}
                                                    aria-controls="panel1a-content"
                                                    id="recebimentos"
                                                >
                                                    <Typography>Detalhes de recebimento</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={12}>
                                                            <TextField
                                                                name="conversa_assunto_recebimento"
                                                                label="Conversa sobre recebimento"
                                                                type="text"
                                                                fullWidth
                                                                variant={"outlined"}
                                                                multiline={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12}>
                                                            <TextField
                                                                name="observacoes_recebimento"
                                                                label="Observações sobre recebimento"
                                                                type="text"
                                                                fullWidth
                                                                variant={"outlined"}
                                                                multiline={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <TextField
                                                                variant={"outlined"}
                                                                name="valorRecebimento"
                                                                label="Valor recebido"
                                                                type="number"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Select variant={"outlined"} defaultValue={"false"} style={{width: '100%'}} name="recebimento" label="Teve recebimento"
                                                                    required>
                                                                <MenuItem value="true">Sim</MenuItem>
                                                                <MenuItem value="false">Não</MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Container>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={fecharNovo} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={()=>formRefNovoRegisto.current.submitForm()} color="primary">
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Form>

            <Form ref={formRefEditar}  onSubmit={GuardarRegisto} initialData={reg[edit]}>
                <Dialog fullScreen={true} maxWidth={"xl"} open={EditarRegisto} onClose={fecharEditar} aria-labelledby="Novo registo">
                    <DialogTitle >Editar registo</DialogTitle>
                    <DialogContent>
                        <Container component="main">
                            <div className={classes.paper}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6}>
                                        <RSelect
                                            options={clientes}
                                            getOptionLabel={option=>option.OrganizationName}
                                            getOptionValue={option=>option.id}
                                            placeholder={"Clientes"}
                                            defaultValue={clienteDefind}
                                            onChange={option=>setClienteDefined(option)}
                                            menuPortalTarget={document.getElementsByClassName("MuiDialogActions-root MuiDialogActions-spacing")[0]}
                                            menuPosition={'fixed'}

                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Select variant={"outlined"} style={{width: '100%'}} name="local" label="Local"
                                                required>
                                            <MenuItem value="telefone">Telefone</MenuItem>
                                            <MenuItem value="presencial">Presencial</MenuItem>
                                            <MenuItem value="email">Email</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="pessoa_contacto"
                                            label="Pessoa de contacto"
                                            type="text"
                                            fullWidth
                                            variant={"outlined"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="venda"
                                            >
                                                <Typography>Detalhes de venda</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            name="conversa_assunto_venda"
                                                            label="Conversa sobre venda"
                                                            type="text"
                                                            fullWidth
                                                            variant={"outlined"}
                                                            multiline={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            name="observacoes_venda"
                                                            label="Observações sobre a venda"
                                                            type="text"
                                                            fullWidth
                                                            variant={"outlined"}
                                                            multiline={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            variant={"outlined"}
                                                            name="valorVenda"
                                                            label="Valor Venda"
                                                            type="number"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <Select variant={"outlined"}  defaultValue={"false"} style={{width: '100%'}} name="venda" label="Teve venda"
                                                                required>
                                                            <MenuItem value="true">Sim</MenuItem>
                                                            <MenuItem value="false">Não</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1a-content"
                                                id="recebimentos"
                                            >
                                                <Typography>Detalhes de recebimento</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            name="conversa_assunto_recebimento"
                                                            label="Conversa sobre recebimento"
                                                            type="text"
                                                            fullWidth
                                                            variant={"outlined"}
                                                            multiline={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            name="observacoes_recebimento"
                                                            label="Observações sobre recebimento"
                                                            type="text"
                                                            fullWidth
                                                            variant={"outlined"}
                                                            multiline={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} sm={6}>
                                                        <TextField
                                                            variant={"outlined"}
                                                            name="valorRecebimento"
                                                            label="Valor recebido"
                                                            type="number"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Select variant={"outlined"} defaultValue={"false"} style={{width: '100%'}} name="recebimento" label="Teve recebimento"
                                                                required>
                                                            <MenuItem value="true">Sim</MenuItem>
                                                            <MenuItem value="false">Não</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Button onClick={apagar} variant={"contained"} color={"secondary"}>Apagar registo</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={fecharEditar} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={()=>formRefEditar.current.submitForm()} color="primary">
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Form>
        </MuiPickersUtilsProvider>
    )
}