import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";

import {Link} from "react-router-dom"
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemText,
    Paper
} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '75hw',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ClienteFicha(props) {
    const classes = useStyles();
    const [data, setData] = useState({encomendas:[], person:[]});
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [listaEncomendas, setListaEncomendas] = React.useState(false);
    const [contactos, setContactos] = React.useState(false);

    useEffect(() => {
        api.get('/cliente/' + props.match.params.id).then(res => {
            if(res.data.data===null){
                window.history.back();
            }
            setData(res.data.data)
        })
    }, [props.match.params.id])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Card className={classes.root}>
                    <CardHeader
                        title={"Cliente: " + data.OrganizationName}
                        titleTypographyProps={{align: 'center'}}
                        subheaderTypographyProps={{align: 'center'}}
                        subheader={"Codigo cliente: " + data.CustomerID}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h4" variant="h5">
                                    <center>Informações:</center>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Nif</b> <br/>{data.FederalTaxID}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Morada</b> <br/>{data.AddressLine1}<br/>{data.AddressLine2}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Codigo Postal</b> <br/>{data.PostalCode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Localidade</b> <br/>{data.LocalityName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Distrito</b> <br/>{data.ProvinceName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <b>Email</b> <br/>{data.EmailAddress}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <a href={"tel:"+data.Telephone1}><b>Telefone</b> <br/>{data.Telephone1}</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography component="span" variant="subtitle1">
                                    <a href={"tel:"+data.MobileTelephone1}><b>Telemovel</b> <br/>{data.MobileTelephone1}</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography component="h4" variant="h6">
                                    <center>Ações:</center>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button fullWidth={true} onClick={() => setListaEncomendas(true)} type="button"
                                        color="primary">Ver encomendas</Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Link to={"/registos/"+data.OrganizationName}>
                                <Button fullWidth={true} type="button"
                                        color="primary">Registo de Visita</Button></Link>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button onClick={()=>setContactos(true)} fullWidth={true} variant={"contained"} color={"primary"}>Contactos</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={listaEncomendas}
                onClose={() => setListaEncomendas(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Lista de encomendas"}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Valor</TableCell>
                                    <TableCell>Ver</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.encomendas.map((item, idx) => {
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>{item.total.toFixed(2) + "€"}</TableCell>
                                            <TableCell><Link to={'/documentos/ver/' + item.id}><Button color="primary"
                                                                                                       variant="contained">Ver</Button></Link></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setListaEncomendas(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullScreen={fullScreen}
                open={contactos}
                onClose={() => setContactos(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Contactos"}</DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                {data.person.map((person, idx)=>{
                                    return (
                                        <Accordion key={idx}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                {person.Name!==null?<Typography className={classes.heading}>{person.Name}</Typography>:<Typography className={classes.heading}>------</Typography>}

                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12}>
                                                        <Accordion key={idx} >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                Telefone

                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <List
                                                                    component="nav"
                                                                    aria-labelledby="nested-list-subheader"
                                                                    width={1}
                                                                    className={classes.root}
                                                                >
                                                                    {
                                                                        person.telephone.map((telephone, idx2)=>{
                                                                            return(
                                                                                <ListItem key={idx2} width={1} component="a" href={"tel:"+telephone.Telephone}  button>
                                                                                    <ListItemText width={1} primary={telephone.Telephone} />
                                                                                </ListItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </List>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                    <Grid xs={12} item>
                                                        <Accordion key={idx}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                Email

                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <List
                                                                    component="nav"
                                                                    aria-labelledby="nested-list-subheader"
                                                                    width={1}
                                                                    
                                                                    className={classes.root}
                                                                >
                                                                    {
                                                                        person.emails.map((mail, idx2)=>{
                                                                            return(
                                                                                <ListItem  key={idx2} width={1} component="a" href={"mailto:"+mail.email}  button >
                                                                                    <ListItemText  width={1} primary={mail.email} />
                                                                                </ListItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </List>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    )
                                })}
                            </TableBody>
   {/*                         <TableBody>
                                {data.person.map((person, idx) => {
                                    return (
                                        <TableRow key={idx}>
                                            <TableCell>{person.Name}</TableCell>
                                            <TableCell><Link to={'/documentos/ver/' + item.id}>{person.telephone.Telephone}</Link></TableCell>
                                            <TableCell><Link to={'/documentos/ver/' + item.id}>{item.total.toFixed(2) + "€"}</Link></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>*/}
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setContactos(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
}
