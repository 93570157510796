import React, {useRef} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import SignatureCanvas from 'react-signature-canvas'

import {useRecoilState} from "recoil";
import {SignaturePanel, } from "../atoms";

export default function Signature ({final}){
    const [open, setOpen] = useRecoilState(SignaturePanel);

    const signatureRef=useRef()

    function save(){
        console.log(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'))
        setOpen(false)
        final(signatureRef.current.getTrimmedCanvas().toDataURL('image/png'))
    }

    return (
        <Dialog
            open={open}
            fullScreen={true}
            onClose={()=>setOpen(false)}
        >
            <DialogTitle id="alert-dialog-title">{"Assinatura de Cliente"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item={true} xs={2}/>
                    <Grid item xs={8} className={"sigContainer"} style={{width:'30vw', height:'30vh'}}>
                        <SignatureCanvas ref={signatureRef} canvasProps={{className:"sigPad"}}/>
                    </Grid>
                    <Grid item={true} xs={2}/>
                    <Grid item={true} xs={4}/>
                    <Grid item={true} xs={2}>
                        <Button fullWidth={true} onClick={()=>signatureRef.current.clear()} color={"primary"} variant={"outlined"}>Limpar</Button>
                    </Grid>
                    <Grid item={true} xs={2}>
                        <Button fullWidth={true} onClick={()=>save()} color={"primary"} variant={"contained"}>Guardar</Button>
                    </Grid>
                    <Grid item={true} xs={4}/>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setOpen(false)} color="primary">
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}