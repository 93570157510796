import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import {TextField} from 'unform-material-ui';
import {Form} from '@unform/web';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from "../../services/api";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function ClienteEditar(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({open: false, message: "", type: "info"});
    const [estado, setEstado] = useState(false);
    const formRef = useRef(null)

    useEffect(() => {
        api.get('/cliente/' + props.match.params.id).then(res => {
            formRef.current.setData(res.data.data.data[0])
            setEstado(!Boolean(Number(res.data.data.data[0].estado)))
        })
    }, [props.match.params.id])

    async function editarCliente(data) {
        setOpen(true);
        if (data.descricao !== "") {
            data.estado = !estado;
            const res = await api.put('/cliente/' + props.match.params.id, data)
            if (res.data.code === 0) {
                setAlert({
                    open: true,
                    message: res.data.message,
                    type: 'success'
                })
            } else {
                setAlert({
                    open: true,
                    message: res.data.message,
                    type: 'error'
                })
            }
        }
        setOpen(false);
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Editar Cliente
                </Typography>
                <Form className={classes.form} ref={formRef} onSubmit={editarCliente} initialData={{estado: true}}
                      noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                label="Nome do cliente"
                                name="nome"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Morada do cliente"
                                name="morada"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Codigo postal"
                                name="codigoPostal"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Localidade"
                                name="localidade"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Nif do cliente"
                                name="nif"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Codigo de cliente"
                                name="codigoCliente"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Distrito"
                                name="distrito"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Telefone"
                                name="telefone"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Telemovel"
                                name="telemovel"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Email"
                                name="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Pessoa a contactar"
                                name="pessoaContactar"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={estado}
                                        color="primary"
                                        onChange={() => setEstado(!estado)}
                                    />
                                }
                                label="Inativo"
                            />
                        </Grid>
                    </Grid>
                    <Collapse in={alert.open}>
                        <Alert severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Collapse>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Guardar
                    </Button>
                </Form>
            </div>
            <Backdrop className={classes.backdrop} open={open} onClick={() => {
            }}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Container>
    );
}
