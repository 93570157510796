import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.register({
    onUpdate: registration => {
        alert('Acabou de sair uma nova atualização, por favor pressione o botão abaixo para atualizar.');
        if (registration && registration.waiting) {
            registration.waiting.postMessage({type: 'SKIP_WAITING'});
        }
        window.location.reload();
    }
});
