import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import api, {auth, url} from '../../services/api'
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",

    },
}));

export default function DocumentosVer(props) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [doc, setDoc] = useState({
        cliente: "",
        customer:{},
        informacoes: [],
        detalhes: [],
        pagamentos: []
    });
    const [total, setTotal] = useState(0);
    const [ultimo, setUltimo] = useState(0);
    const [anterior, setAnterior] = useState(false);
    const [obsData,setObsData]=useState("");

    // eslint-disable-next-line
    useEffect(() => {
        setOpen(true)
        getInfo()
        // eslint-disable-next-line
    }, [props.match.params.id]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getInfo() {
        api.get('documentos/' + props.match.params.id).then(res => {
            if (res.data.code === 0) {
                setDoc(res.data.data)
                setTotal(res.data.data.docValue);
                setObsData(res.data.data.Obs)
                setOpen(false)
            }
        })

        api.get('documentos/ultimo').then(res => {
            if (res.data.code === 0) {
                setUltimo(res.data.data)
            }
        })

        api.get('documentos/' + (parseInt(props.match.params.id) - 1)).then(res => {
            if (res.data.code === -10) {
                setAnterior(true)
            } else {
                setAnterior(false)
            }
        })
    }


    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Backdrop className={classes.backdrop} open={open} onClick={() => {
            }}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <div className={classes.paper}>
                <Grid container spacing={3} style={{marginBottom: 10}}>
                    <Grid item xs={3} sm={3}>
                        <Link to={`/documentos/ver/` + (parseInt(props.match.params.id) - 1)}
                              onClick={(e) => anterior === true ? e.preventDefault() : null}>
                            <Button
                                disabled={anterior}
                                variant="outlined"
                                color="primary"
                                className={classes.button}
                                startIcon={<ArrowBackIosIcon/>}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Typography component="h1" variant="h5">
                            Enc. Nº {props.match.params.id}<br/>
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <Link to={`/documentos/ver/` + (parseInt(props.match.params.id) + 1)}
                              onClick={(e) => parseInt(props.match.params.id) + 1 === parseInt(ultimo + 1) ? e.preventDefault() : null}>
                            <Button
                                disabled={parseInt(props.match.params.id) + 1 === ultimo + 1}
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<ArrowForwardIosIcon/>}
                            />
                        </Link>
                    </Grid>
                </Grid>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h5" component="p">
                                    Cliente: {doc.customer.OrganizationName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Ref.</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Preço</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                                <TableCell>Preço da linha</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {doc.detalhes.map((row, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{row.ref}</TableCell>
                                                    <TableCell>{row.artigo}</TableCell>
                                                    <TableCell>{parseFloat(row.preco).toFixed(2)}€</TableCell>
                                                    <TableCell>{row.quantidade}</TableCell>
                                                    <TableCell>{(parseFloat(row.quantidade) * parseFloat(row.preco)).toFixed(2)}€</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Typography variant="h5" component="p" style={{marginTop:20,marginBottom:20}}>
                                    Observações
                                </Typography>
                                <TextareaAutosize style={{width:'100%'}} aria-label="minimum height" rowsMin={3} placeholder="Observações..." name="observacoes" value={obsData} disabled/>

                                <Typography variant="h5" component="p" style={{marginTop: 20}}>
                                    Valor documento: {total}€
                                </Typography>

                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Link to={"/documentos/editar/" + props.match.params.id}><Button variant="outlined"
                                                                                                 color="primary"
                                                                                                 fullWidth={true}>Editar</Button></Link>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <a target={"_blank"} rel="noopener noreferrer"
                                   href={url + "pdf/docVenda/" + props.match.params.id + "/" + auth()}><Button
                                    variant="contained" color="primary" fullWidth={true}>Obter documento</Button></a>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </Container>
    )
}
