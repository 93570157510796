import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import 'moment/locale/pt';



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function ClientesListar() {
    const classes = useStyles();

    const [clientes, setClientes] = useState([{}]);
    const [lista, setLista] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [lastSync, setSync] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));

    useEffect(() => {
        setLoading(true)
            api.get('/cliente').then(res => {
                if (res.data.code === 0) {
                    setClientes(res.data.data)
                    setLista(res.data.data)
                    setSync(res.data.lastSync)
                }
                setLoading(false)

            })
    }, [])

    function pesquisa(e) {
        // eslint-disable-next-line array-callback-return
        let temp = lista.filter(value => {
            if(value.CustomerID.toString()===null){
                if ((!value.OrganizationName.toLowerCase().indexOf(e.target.value.toLowerCase(), -1)) /*|| (!value.cliente.codigoCliente.toLowerCase().indexOf(e.target.value.toLowerCase(), -1))*/) {
                    return value;
                }
            }else{
                if ((!value.OrganizationName.toLowerCase().indexOf(e.target.value.toLowerCase(), -1)) || (!value.CustomerID.toString().toLowerCase().indexOf(e.target.value.toLowerCase(), -1))) {
                    return value;
                }
            }

        })
        setClientes(temp)
    }

    const columns = [
        {
            name: "Codigo",
            selector: "CustomerID",
            sortable: true
        },
        {
            name: "Nome",
            selector: "OrganizationName",
            sortable: true
        },
        {
            name: "",
            cell: row => <Link to={"/registos/" + row.OrganizationName}><Button variant={"contained"}
                                                                          color={"primary"}>Visita</Button></Link>
        },{
            name: "",
            cell: row => <Link to={"/clientes/" + row.id}><Button variant={"contained"}
                                                                          color={"primary"}>Ficha</Button></Link>
        }
    ];


    return (
        <Container component="main" maxWidth="md">
            <TextField label="Pesquisa" onChange={pesquisa}/>
            <div className={classes.paper}>
                <DataTable
                    title="Clientes"
                    columns={columns}
                    data={clientes}
                    sortIcon={<SortIcon/>}
                    pagination
                    selectableRowsComponent={Checkbox}
                    contextMessage={{
                        singular: 'artigo',
                        plural: 'artigos',
                        message: 'selecionada\\s'
                    }}
                    noDataComponent={"Não foi encontrados clientes"}
                    progressPending={loading}
                    progressComponent={<CircularProgress/>}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Linhas por pagina:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: false,
                        selectAllRowsItemText: 'Todas'
                    }}
                />
                <p>Ultima sincronização: {moment(lastSync).locale("pt").fromNow()}</p>
            </div>
        </Container>
    );
}
