import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import PrivateRoute from "./services/PrivateRoute";
import Login from "./pages/Login";
import ClientesListar from "./pages/clientes/Listar";
import ClientesListarTodos from "./pages/clientes/ListarTodos";
import ClienteCriar from "./pages/clientes/Criar";
import ClienteFicha from "./pages/clientes/Ficha";
import ClienteEditar from "./pages/clientes/Editar";
import DocumentosCriar from "./pages/documentos/Criar";

import DocumentosListar from "./pages/documentos/Listar";
import DocumentosVer from "./pages/documentos/VerDocumento";
import DocumentosEditar from "./pages/documentos/Editar";
import UtilizadoresListar from "./pages/utilizadores/Listar";
import UtilizadorFicha from "./pages/utilizadores/Ficha";
import Dashboard from "./pages/Dashboard";
import RegistoDiario from "./pages/RegistoDiario";

import OrcamentosCriar from "./pages/orcamentos/Criar";
import OrcamentosListar from "./pages/orcamentos/Listar";
import OrcamentosVer from "./pages/orcamentos/VerDocumento";
import OrcamentosEditar from "./pages/orcamentos/Editar";
import {Auth} from "./pages/Auth";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login}/>
            <Route exact path="/auth/:authToken" component={Auth} />

            <PrivateRoute exact path="/clientes" component={ClientesListar}/>
            <PrivateRoute exact path="/clientes/todos" component={ClientesListarTodos}/>
            <PrivateRoute exact path="/clientes/criar" component={ClienteCriar}/>
            <PrivateRoute exact path="/clientes/editar/:id" component={ClienteEditar}/>
            <PrivateRoute exact path="/clientes/:id" component={ClienteFicha}/>

            <PrivateRoute exact path="/documentos" component={DocumentosCriar}/>
            <PrivateRoute exact path="/documentos/lista" component={DocumentosListar}/>
            <PrivateRoute exact path="/documentos/ver/:id" component={DocumentosVer}/>
            <PrivateRoute exact path="/documentos/editar/:id" component={DocumentosEditar}/>

            <PrivateRoute exact path="/orcamentos" component={OrcamentosCriar}/>
            <PrivateRoute exact path="/orcamentos/lista" component={OrcamentosListar}/>
            <PrivateRoute exact path="/orcamentos/ver/:id" component={OrcamentosVer}/>
            <PrivateRoute exact path="/orcamentos/editar/:id" component={OrcamentosEditar}/>

            <PrivateRoute exact path="/utilizadores" component={UtilizadoresListar}/>
            <PrivateRoute exact path="/utilizadores/:id" component={UtilizadorFicha}/>

            <PrivateRoute path="/dashboard" component={Dashboard}/>
            <PrivateRoute path="/registos/:cliente?" component={RegistoDiario}/>
            <Route path="*" component={() => <h1>Page not found</h1>}/>
        </Switch>
    </BrowserRouter>
);

export default Routes;
