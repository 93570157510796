import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function MenuList() {
    const classes = useStyles();
    const [clientes, setClientes] = React.useState(false);
    const [documentos, setDocumentos] = React.useState(false);
    const [orcamentos, setOrcamentos] = React.useState(false);

    const handleClickClientes = () => {
        setClientes(!clientes);
    }

    const handleClickDocumentos = () => {
        setDocumentos(!documentos);
    }

    const handleClickOrcamentos = () => {
        setOrcamentos(!orcamentos);
    }

    return (
        <>
            <List>
                <Link to={"/dashboard"}>
                    <ListItem button>
                        <ListItemText primary="Dashboard"/>
                    </ListItem>
                </Link>
                <Divider/>
                <Link to={"/registos"}>
                    <ListItem button>
                        <ListItemText primary="Registo de visita"/>
                    </ListItem>
                </Link>
                <Divider/>
                <List>
                    <ListItem button onClick={handleClickDocumentos}>
                        <ListItemText primary="Encomendas"/>
                    </ListItem>
                    <Collapse in={documentos} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to={"/documentos"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Criar encomenda"/>
                                </ListItem>
                            </Link>
                        </List>
                        <List component="div" disablePadding>
                            <Link to={"/documentos/lista"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Listar encomendas"/>
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </List>
                <Divider/>
                <List>
                    <ListItem button onClick={handleClickOrcamentos}>
                        <ListItemText primary="Orcamentos"/>
                    </ListItem>
                    <Collapse in={orcamentos} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link to={"/orcamentos"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Criar orçamento"/>
                                </ListItem>
                            </Link>
                        </List>
                        <List component="div" disablePadding>
                            <Link to={"/orcamentos/lista"}>
                                <ListItem button className={classes.nested}>
                                    <ListItemText primary="Listar orçamentos"/>
                                </ListItem>
                            </Link>
                        </List>
                    </Collapse>
                </List>
                <Divider/>
                <ListItem button onClick={handleClickClientes}>
                    <ListItemText primary="Clientes"/>
                </ListItem>
                <Collapse in={clientes} timeout="auto" unmountOnExit>
                    {/*<List component="div" disablePadding>
                        <Link to={"/clientes/criar"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Criar cliente"/>
                            </ListItem>
                        </Link>
                    </List>*/}
                    <List component="div" disablePadding>
                        <Link to={"/clientes"}>
                            <ListItem button className={classes.nested}>
                                <ListItemText primary="Listar clientes"/>
                            </ListItem>
                        </Link>
                    </List>
                </Collapse>
            </List>
            {/*            <Divider />
            <Link to={"/utilizadores"}>
                <ListItem button>
                    <ListItemText primary="Utilizadores" />
                </ListItem>
            </Link>*/}
        </>
    )
}
