import {atom} from "recoil";
import * as auth from "../services/auth"

export const usernameState = atom({
    key: "usernameState",
    default: auth.nome(),
});

export const SignaturePanel = atom({
    key:"SignaturePanel",
    default:false
})