import {isAuthenticated} from "./auth";
import React from "react";
import {Redirect, Route} from "react-router-dom";
import Template from "../components/Template";

export default ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Template>
                    <Component {...props} />
                </Template>
            ) : (
                <Redirect to={{pathname: "/", state: {from: props.location}}} from={'/'}/>
            )
        }
    />
);
