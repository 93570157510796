import axios from "axios";
import {getSession} from "./auth";
import Swal from "sweetalert2";

export const auth = () => {
    const session = getSession();
    return btoa(session.data.user + ':' + session.data.token)
};

//export const url = "http://" + window.location.hostname + "/api/v1/";
export const url =window.location.protocol+"//api."+window.location.hostname+"/public/api/v1/";
//export const url ="https://api.dr0002.drosera.pt/public/api/v1/";

const api = axios.create({
    baseURL: url
});

api.interceptors.request.use(async config => {
    const session = getSession();
    const auth = btoa(session.data.user + ':' + session.data.token);
    if (session.status === true) {
        config.headers.Authorization = `Basic ` + auth;
    }
    return config;
});

api.interceptors.response.use(async res => {
    if (res.status === 401) {
        await Swal.fire(
            'Informação!',
            res.data.message,
            'error',
        )
        window.location.href = "/";
    }
    if (res.status === 500) {
        await Swal.fire(
            'ERRO!',
            'Ocurreu um erro no servidor por favor tente mais tarde',
            'error',
        )
        window.location.href = "/";
    }
    // Important: response interceptors **must** return the response.
    return res;
}, function (error) {
    if (401 === error.response.status) {
        Swal.fire({
            title: "A sua sessão expirou",
            type: "warning",
            showCancelButton: false,
            closeOnConfirm: false
        }).then(r => window.location = '/');
    } else {
        return Promise.reject(error);
    }
})

export default api;
