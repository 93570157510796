import React, {useEffect, useState} from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import api from '../../services/api'
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/Delete';
import TableBody from "@material-ui/core/TableBody";
import Swal from "sweetalert2";
import {useHistory} from "react-router-dom"


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: "75vw",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    table: {
        //minWidth: "35vw",
    },
    table2: {
        maxWidth: "35vw",
    },
    select: {
        minWidth: "35vw",
    }
}));

export default function OrcamentosEditar(props) {
    const classes = useStyles();
    const [infoArtigo, setInfoArtigo] = useState({
        preco: Number().toFixed(2),
        id: "",
        ref: "",
        artigo: "",
        quantidade: Number(),
    });
    const [totalDoc, setTotalDoc] = useState(0.00);
    const [pagamento, setPagamento] = useState(false);
    const [edit, setEdit] = useState(false);
    const history = useHistory();
    const [documento, setDocumento] = useState({
        detalhes: [],
    });

    useEffect(() => {
        api.get('/orcamentos/' + props.match.params.id).then(res => {
            if (res.data.code === 0) {
                setDocumento(res.data.data);
                let temp = 0;
                for (let row of res.data.data.detalhes) {
                    temp += row.totalLinha

                }
                setTotalDoc(temp)
            }
        })
    }, [props.match.params.id]);

    function defenirLinhas() {
        const temp = documento;
        let total = 0.00;
        temp.detalhes.push({
            preco: infoArtigo.preco,
            ref: infoArtigo.ref,
            artigo: infoArtigo.artigo,
            quantidade: Number(infoArtigo.quantidade).toFixed(parseInt(infoArtigo.MaximumDecimals)),
        });
        for (let row of temp.detalhes) {
            total += (parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total);
        setInfoArtigo({
            preco: Number().toFixed(2),
            ref: "",
            artigo: "",
            quantidade: "",
        })

        setDocumento(temp)
    }

    function definePreco(e) {
        if (!isNaN(Number(e.target.value))) {
            if (Number(e.target.value) >= 0) {
                setInfoArtigo({
                    preco: e.target.value,
                    ref: infoArtigo.ref,
                    artigo: infoArtigo.artigo,
                    quantidade: infoArtigo.quantidade,
                })
            }
        }
    }

    function defineQuantidade(e) {
        if (e.target.value >= 0) {
            setInfoArtigo({
                preco: infoArtigo.preco,
                ref: infoArtigo.ref,
                artigo: infoArtigo.artigo,
                quantidade: e.target.value,
            })
        }
    }

    function defineDescricao(e) {
        setInfoArtigo({
            preco: infoArtigo.preco,
            ref: infoArtigo.ref,
            artigo: e.target.value,
            quantidade: infoArtigo.quantidade,
        })
    }

    function defineRef(e) {
        setInfoArtigo({
            preco: infoArtigo.preco,
            ref: e.target.value,
            artigo: infoArtigo.artigo,
            quantidade: infoArtigo.quantidade,
        })
    }

    function guardarDoc() {
        console.log(documento)
        api.put('/orcamentos/' + props.match.params.id, documento).then(async res => {
            setPagamento(false)
            await Swal.fire({
                title: "Informação",
                text: res.data.message,
                icon: res.data.code === 0 ? "success" : "error",
                timer: 3000
            })
            if (res.data.code === 0) {
                history.push("/orcamentos/lista")
            }
        })
    }

    function editarLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp = documento.detalhes.filter((value, index) => {
            if (index === idx) {
                setInfoArtigo(value);
            } else {
                return value;
            }
        })
        let total = 0.00;
        for (let row of newTmp) {
            total += (parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        let temp = documento
        temp.detalhes = newTmp
        setDocumento(temp);
    }

    function removerLinha(idx) {
        // eslint-disable-next-line array-callback-return
        const newTmp = documento.detalhes.filter((value, index) => {
            if (index !== idx) {
                return value;
            }
        })
        let total = 0.00;
        for (let row of newTmp) {
            total += (parseFloat(row.quantidade) * parseFloat(row.preco))
        }
        setTotalDoc(total)
        let temp = documento
        temp.detalhes = newTmp
        setDocumento(temp);

    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Editar encomenda <br/>
                </Typography>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TableContainer component={Paper} style={{marginBottom: 10, marginTop: 20}}>
                                    <Table className={classes.table} aria-label="Descricao do artigo">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Codigo artigo</TableCell>
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Preço</TableCell>
                                                <TableCell>Quantidade</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell><TextField name="referencia" onChange={defineRef}
                                                                      value={infoArtigo.ref}/></TableCell>
                                                <TableCell><TextField name="descricao" onChange={defineDescricao}
                                                                      value={infoArtigo.artigo}/></TableCell>
                                                <TableCell><TextField name="preco" onChange={definePreco} type="number"
                                                                      value={infoArtigo.preco}/></TableCell>
                                                <TableCell><TextField name="quantidade" onChange={defineQuantidade}
                                                                      type="number"
                                                                      value={infoArtigo.quantidade}/></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={defenirLinhas}
                                        disabled={infoArtigo.itemCode === "" || parseFloat(infoArtigo.quantidade.toString()) <= 0 || infoArtigo.quantidade === ""}
                                        fullWidth style={{marginTop: 20}} variant="contained" color="primary">Adicionar
                                    artigo</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="Artigos do documento">
                                        <TableHead>
                                            <TableRow>
                                                {edit === false ? (<TableCell>Codigo artigo</TableCell>) : (null)}
                                                <TableCell>Descrição</TableCell>
                                                <TableCell>Preço</TableCell>
                                                {edit === false ? (<TableCell>Quantidade</TableCell>) : (
                                                    <TableCell colSpan={2}>
                                                        <center>Ações</center>
                                                    </TableCell>)}
                                                {edit === false ? (<TableCell>Preço da linha</TableCell>) : (null)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {documento.detalhes.map((row, index) =>
                                                <TableRow key={index}>
                                                    {edit === false ? (<TableCell>{row.ref}</TableCell>) : null}
                                                    <TableCell>{row.artigo}</TableCell>
                                                    <TableCell>{isNaN(parseFloat(row.preco))?"0.00":parseFloat(row.preco).toFixed(2)}€</TableCell>
                                                    {edit === false ? (<TableCell>{row.quantidade}</TableCell>) : (
                                                        <TableCell><Button onClick={() => editarLinha(index)}
                                                                           variant="contained" color="primary">Editar
                                                            linha</Button></TableCell>)}
                                                    {edit === false ? (
                                                        <TableCell>{isNaN(parseFloat(row.quantidade) * parseFloat(row.preco))?"0.00":(parseFloat(row.quantidade) * parseFloat(row.preco)).toFixed(2)}€</TableCell>) : (
                                                        <TableCell><Button variant="contained" color="secondary"
                                                                           className={classes.button}
                                                                           startIcon={<DeleteIcon/>}
                                                                           onClick={() => removerLinha(index)}>Remover
                                                            linha</Button></TableCell>)}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={() => setPagamento(true)} disabled={documento.detalhes.length <= 0}
                                        fullWidth style={{marginTop: 20}} variant="contained" color="primary">Guardar
                                    encomenda total: {totalDoc.toFixed(2)}€</Button>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={edit}
                                            onChange={() => setEdit(!edit)}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Editar documento"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                open={pagamento}
                onClose={() => setPagamento(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Guardar documento?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        O documento dá no total {totalDoc.toFixed(2)}€ deseja guardar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setPagamento(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => guardarDoc()} color="primary" autoFocus>
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
